@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {}

:root {
    --black-color       : #000000;
    --text-color        : #1B2040;
    --white-color       : #ffffff;
    --white-yellow-color: #FAF3D9;
    --gray-color-600    : #2A2C2B;
    --gray-color-500    : #484848;
    --gray-color-400    : #BCBCBC;
    --gray-color-300    : #00000029;
    --gray-color-200    : #0000001F;
    --mint-color        : #E4F8EF;
    --green-color       : #7ABC7C;
    --green-color       : #7ABC7C;
    --dark-green-color  : #7ABC7C;
    --light-green-color : #96d398;
    --red-color         : #F29696;
    --yellow-color      : #F2D596;
    --light-grey-color  : #F8F9FA;
}

body {
    margin          : 0;
    padding         : 0;
    border          : none;
    font-size       : 18px;
    font-weight     : 300;
    background-color: #F8F9FA;
    font-family     : museo-sans, sans-serif;
    color           : var(--text-color);
}


h1,
h2,
h3,
h4 {
    color      : var(--green-color);
    font-weight: 800;
}

h1 {
    font-size: 35px;
}

.align-right {
    text-align: right !important;
}

a,
a:hover {
    color: var(--green-color);
}

.App-logo {
    height        : 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    color           : white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.logo {
    align-items     : flex-end;
    display         : flex;
    padding-top     : 50px;
    padding-bottom  : 50px;
    position        : absolute;
    z-index         : 999;
    width           : 270px;
    background-color: #F8FAFD;
}

.sidebar {
    width   : 310px;
    height  : 100vh;
    position: fixed;
    top     : 0px;
    left    : 0px;
}

nav {
    padding-top     : 140px;
    width           : 310px;
    height          : 100vh;
    left            : 0px;
    top             : 0px;
    background-color: #F8FAFD;
    display         : inline-block;
    overflow        : scroll;
}

nav ul {
    list-style: none;
    margin    : 20px;
    padding   : 0px;
}

nav ul li {
    color         : var(--text-color);
    list-style    : none;
    font-weight   : bold;
    padding-left  : 20px;
    padding-bottom: 30px;
    position      : relative;
}

nav ul li a {
    color          : var(--text-color);
    list-style     : none;
    font-weight    : bold;
    text-decoration: none;
    transition     : 0.2s ease color;
}

nav ul li a:hover {
    text-decoration: none;
}

.red {
    color: var(--red-color)
}

nav ul li.current_page:before {
    width                     : 11px;
    height                    : 11px;
    border-radius             : 100%;
    border-bottom-right-radius: 0px;
    background-color          : var(--green-color);
    position                  : absolute;
    display                   : block;
    content                   : '';
    left                      : 0px;
    top                       : 4px;
}

.innerContainerWrapper {
    display: flex;
}

.sidebar_right {
    width           : 290px;
    height          : 100vh;
    position        : fixed;
    right           : 0px;
    top             : 0px;
    background-color: #F8FAFD;
    display         : inline-block;
    padding         : 20px;
    overflow        : scroll;
    transition      : margin 0.5s ease;
    margin-right    : 0px;
}

.secondSidebar {
    width      : 300px;
    height     : 100vh;
    overflow   : scroll;
    margin-left: 0px;
    margin-top : 0px;
    height     : 100vh;
    position   : fixed;
    left       : 300px;
    top        : 0;
    box-shadow : 3px 0 20px rgba(0, 0, 0, 0.03);
}

.containerWithSecondSidebar {
    width   : calc(100% - 300px);
    position: absolute;
    left    : 300px;
    top     : 0;
    padding : 30px;
}

.wrapper {
    width      : calc(100%);
    display    : flex;
    top        : 0px;
    /* left    : 310px; */
    padding    : 0px;
}

.content_wrapper {
    width   : calc(100% - 300px);
    display : inline-block;
    position: absolute;
    top     : 0px;
    left    : 300px;
    padding : 30px;
}

/* Login */
.login_wrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100vh;
    flex-direction : column;

    img {
        width        : 300px;
        max-width    : 80%;
        margin-bottom: 30px;
    }
}

.login_container {
    display         : inline-flex;
    flex-direction  : column;
    padding         : 50px;
    width           : 90%;
    max-width       : 600px;
    background-color: white;
    box-shadow      : 0px 3px 20px rgba(0, 0, 0, 0.08);
    border-radius   : 20px;
}

input {
    font-family: "Montserrat" !important;
}

.MuiFormControl-root.valued label {
    top             : 10px;
    transform       : translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}

/* Select */
.MuiFormControl-root.selectFormControl {
    box-shadow      : 0px 3px 20px rgba(0, 0, 0, 0.08);
    background-color: white !important;
    border-radius   : 15px !important;
    width           : 100%;
    margin-bottom   : 20px !important;

    .MuiInputLabel-shrink {
        transform: translate(0, 9px) scale(0.8) !important;
    }

    .MuiSelect-root {
        width           : 100%;
        background-color: transparent !important;
        border-radius   : 15px !important;
        padding-top     : 31px !important;
        padding-left    : 20px !important;
        padding-bottom  : 12px !important;
    }

    .MuiSelect-icon {
        background-image   : url('./assets/icons/dropdown.png');
        background-size    : 25px;
        background-repeat  : no-repeat;
        background-position: center;
        margin-right       : 20px;
        margin-top         : 5px !important;
    }

    .MuiSelect-icon path {
        display: none !important;
    }

    .Mui-focused .MuiSelect-root {
        background-color: transparent !important;
    }

    label {
        margin-top: 2px;
    }

    label+.MuiInput-formControl {
        margin-top: 0px !important;
    }

    .MuiFormControl-root {
        width: 100%;
    }
}



/* DatePicker */
.react-datepicker__input-container input {
    width        : 100%;
    border       : 0;
    box-shadow   : 0px 3px 30px rgba(0, 0, 0, 0.08);
    height       : 62px;
    border-radius: 15px;
    outline      : none;
    padding-top  : 17px !important;
    padding-left : 20px !important;
    color        : var(--text-color);
    font-size    : 1rem;
}


.MuiTextField-root {
    width        : 100%;
    font-family  : inherit;
    border       : 2px solid var(--green-color) !important;
    border-radius: 15px;
    margin-bottom: 20px !important;
    padding-left : 20px !important;
    padding-top  : 0px !important;
}

.MuiTextField-root.filled {
    border          : 0px !important;
    background-color: white;
    box-shadow      : 0px 3px 20px rgba(0, 0, 0, 0.08);
}

.MuiTextField-root .MuiInput-root {
    margin: 0px !important;
}

.MuiTextField-root .MuiInput-root input {
    padding-top   : 17px !important;
    padding-bottom: 0px !important;
    height        : 45px !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    border: none !important;
}

.MuiInput-inputMultiline {
    margin-top    : 23px !important;
    min-height    : 11px !important;
    padding-bottom: 7px !important;
}

.MuiInput-underline.Mui-focused:before {
    border: none !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
    color      : var(--text-color) !important;
    opacity    : 0.57;
    font-family: inherit;
}

.MuiInputLabel-root {
    font-family: inherit !important;
}

.MuiInputBase-root {
    margin-bottom: 15px !important;
}

.MuiInputBase-input {
    color: var(--text-color) !important;
}

.Mui-error,
.MuiFormLabel-root.Mui-focused.Mui-error,
.formInputWrapper .error {
    color  : red !important;
    opacity: 1 !important;
}

.MuiInput-underline.Mui-error:before,
.MuiInput-underline.Mui-error:after {
    border-bottom: 0px solid red !important;
}

.MuiInputBase-root {
    width        : 100%;
    margin-bottom: 4px !important;
}

.MuiPopover-root {
    z-index    : 9999 !important;
    font-family: "Montserrat" !important;
}

label.MuiFormLabel-root {
    // font-size  : 13px;
    margin-left: 20px !important;
}

.MuiTextField-root label {
    font-size: 15px !important;
}

.MuiTextField-root label.MuiInputLabel-shrink {
    top: 10px;
}


label {
    margin-bottom: 0px;
}

.formInputWrapper {
    position     : relative;
    color        : var(--text-color) !important;
    margin-bottom: 20px !important;
}

.formInputWrapper label {
    position      : absolute;
    z-index       : 2;
    cursor        : text;
    color         : var(--text-color) !important;
    opacity       : 0.57;
    font-size     : 15px;
    margin-left   : 20px !important;
    transform     : translate(0, 24px) scale(1);
    font-weight   : 400;
    line-height   : 1;
    letter-spacing: 0.00938em;
    transition    : color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.formInputWrapper label.focused {
    top             : 10px;
    transform       : translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}

/* Select */

.MuiSelect-root {
    font-family: "Montserrat" !important;
}

.statusSelect {
    width        : 130px;
    padding      : 5px 15px;
    border-radius: 15px;

    &.active {
        background-color: var(--green-color);
    }

    &.paused {
        background-color: var(--yellow-color);
    }

    &.canceled {
        background-color: var(--red-color);
    }

    .MuiSelect-selectMenu {}
}

.button {
    display         : inline-block;
    border-radius   : 10px;
    cursor          : pointer;
    background-color: var(--green-color);
    color           : #FFFFFF;
    padding         : 15px 30px;
    transition      : 0.2s ease all;
    border          : 0px;
    font-size       : inherit;
    font-family     : inherit;
    text-decoration : none;
}

.button.go_back {
    margin-bottom: 20px;
}

.button.save {
    margin-top: 20px;
}

.button.iconButton {
    width          : 62px;
    height         : 62px;
    display        : flex;
    align-items    : center;
    justify-content: center;

    &.delete {
        background-color: var(--red-color);
    }

    img {
        width     : 25px;
        height    : 25px;
        object-fit: contain
    }
}

.button:hover {
    box-shadow     : 0px 3px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color          : white;
}

.go_back_button {
    color       : var(--green-color);
    font-weight : 600;
    padding-left: 35px;
    margin-left : -5px;
    position    : relative;
    transition  : all 0.2s ease;
}

.go_back_button:hover {
    text-decoration: none;
    color          : var(--green-color);
}

.go_back_button:hover:before {
    background-color: var(--green-color);
}

.go_back_button:before {
    content            : '';
    width              : 25px;
    height             : 25px;
    display            : block;
    border-radius      : 100px;
    color              : var(--green-color);
    position           : absolute;
    left               : 0px;
    top                : 0px;
    transition         : all 0.2s ease;
    background-image   : url(./assets/icons/arrow_left_white.png);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 11px;
    background-color   : var(--green-color);
}


.add_button {
    color         : var(--green-color);
    font-weight   : 600;
    padding-bottom: 2px;
    transition    : all 0.2s ease;
    position      : relative;
    margin-left   : 30px;
    opacity       : 0.8;
    display       : inline-block;
    cursor        : pointer;
    border-bottom : 2px solid transparent;
}

.add_button:hover {
    text-decoration: none;
    opacity        : 1;
}

a.add_button:before {
    left: -30px;
    top : 1px;
}

.add_button:before {
    content            : '';
    width              : 20px;
    height             : 20px;
    display            : block;
    border-radius      : 100px;
    background-color   : var(--green-color);
    position           : absolute;
    left               : -30px;
    top                : 2px;
    transition         : all 0.2s ease;
    background-image   : url(./assets/icons/plus_white.png);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 10px;
}


.loading_container {
    width           : 100%;
    height          : 100vh;
    position        : fixed;
    z-index         : 9999;
    background-color: rgba(255, 255, 255, 0.8);
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : 0px;
    left            : 0px;
}

.loading_container.relative {
    width           : 100%;
    height          : 50vh;
    position        : relative;
    z-index         : 9999;
    background-color: transparent;
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : auto;
    left            : auto;
}

.loadingSpinner {
    color: var(--green-color) !important;
}

.checkbox_simple {
    color: var(--text-color);
}

.switch {
    display         : inline-block;
    background-color: transparent;
    border          : 1px solid var(--text-color);
    padding         : 4px 12px;
    color           : rgba(20, 42, 69, 0.57);
    border-radius   : 14px 14px 0px 14px;
    cursor          : pointer;
    transition      : background-color 0.2s ease;
    transition      : padding 0.2s ease;
    margin-right    : 10px;
}

.switch.selected {
    background-image   : url(./assets/icons/check.png);
    background-position: center right 10px;
    background-repeat  : no-repeat;
    background-size    : 16px;
    padding-right      : 30px;
    color              : rgba(20, 42, 69, 1);
    background-color   : rgba(20, 42, 69, 0.36);
}

.root:hover,
.switch:hover {
    background-color: 'transparent',
}

.icon {
    border-radius   : 3;
    width           : 16;
    height          : 16;
    box-shadow      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
    background-color: '#f5f8fa';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))';
}

.root.Mui-focusVisible {
    outline       : '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
}

.checkedIcon {
    background-color: '#137cbd';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))';
}

.checkedIcon:before {
    display: 'block';
    width  : 16;
    height : 16;
    content: '';
}

.checkedIcon input:hover {
    background-color: '#106ba3',
}

.MuiTouchRipple-root {
    display: none !important;
}

.MuiCheckbox-root {
    padding      : 0px !important;
    margin       : 0px !important;
    padding-right: 10px !important;
}

.MuiCheckbox-root:hover {
    background-color: transparent !important;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    background-image   : url(./assets/icons/check.png);
    background-position: center 5px;
    background-repeat  : no-repeat;
    background-size    : 18px;
}

.MuiCheckbox-root .MuiIconButton-label {
    border-radius: 5px;
    border       : 1px solid var(--text-color);
    width        : 25px;
    height       : 25px;
}

.MuiCheckbox-root .MuiSvgIcon-root {
    display: none !important;
}

.MuiFormControlLabel-root {
    margin-left: 0px !important;
}

.MuiFormControlLabel-label {
    font-family: inherit !important;
}

.disabled {
    opacity       : 0.3;
    pointer-events: none;
}

.upload_image_button {
    width              : 100%;
    background-color   : #E7EBF0;
    border-radius      : 10px 10px 0px 10px;
    background-image   : url(./assets/icons/upload_image.png);
    background-position: top 30% center;
    background-size    : 50%;
    background-repeat  : no-repeat;
    position           : relative;
    cursor             : pointer;
    transition         : 0.2s ease all;
}

.upload_image_button:before {
    padding-bottom: 120%;
    content       : '';
    display       : block;
}

.upload_image_button:after {
    content    : 'Upload article image';
    display    : block;
    width      : 100%;
    position   : absolute;
    bottom     : 30px;
    font-weight: 900;
    text-align : center;
}

.upload_image_button:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}


/* Table */

.MuiTableRow-root {
    background-color: #FFFFFF;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
    background-color: #F8FAFD;
}

.MuiTableHead-root .MuiTableCell-root {
    font-weight: 800;
}

.MuiTableCell-root {
    border-bottom: none !important;
    font-family  : inherit !important;
}


.MuiTableRow-root.no_background,
.MuiTableBody-root .MuiTableRow-root.no_background:nth-child(odd) {
    background-color: transparent;
    border-top      : 1px solid #E7EBF0;
}

.MuiTableRow-root.no_background:first-child {
    border-top: none;
}

.MuiTableRow-root.selectedRow {
    background-color: var(--green-color) !important;
}

.MuiAutocomplete-root {
    height: 66px;

    .MuiInputBase-root {
        padding-top: 13px;
    }

    .MuiTextField-root .MuiInput-root input {
        padding-top: 7px !important;
    }
}

/* Notifications */

.notification_container {
    width              : 500px;
    display            : inline-flex;
    min-height         : 50px;
    padding            : 10px;
    padding-left       : 44px;
    padding-right      : 44px;
    max-width          : 90%;
    border-radius      : 10px 10px 0px 10px;
    color              : #FFFFFF;
    background-color   : var(--green-color);
    background-image   : url(./assets/icons/x.png);
    background-repeat  : no-repeat;
    background-size    : 14px;
    background-position: left 10px center;
    align-items        : center;
    position           : fixed;
    bottom             : 20px;
    right              : 20px;
}

.notification_container.success {
    background-image: url(./assets/icons/success.png);
    background-color: #8BBA62;
}

.notification_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 10px;
    top                : 10px;
    background-image   : url(./assets/icons/x.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.popup_close_button_overlay {
    width           : 100vw;
    height          : 100vh;
    background-color: rgba(0, 0, 0, 0.76);
    display         : inline-flex;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    justify-content : center;
    align-items     : center;
    z-index         : 9999;
    overflow        : scroll;
}

.popup_container {
    display         : inline-block;
    background-color: #FFFFFF;
    padding         : 30px;
    width           : 90%;
    max-width       : 700px;
    z-index         : 99999;
    position        : relative;
}

.popup_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 20px;
    top                : 20px;
    background-image   : url(./assets/icons/x_dark.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.small_button {
    display    : inline-block;
    cursor     : pointer;
    color      : var(--text-color);
    font-weight: 700;
}

.editor {
    background-color: #F8FAFD;
    padding         : 10px;
}

/* accordion */

.accordion {
    width  : 100%;
    display: inline-block;
}

.accordion_title {
    width   : 100%;
    display : inline-block;
    margin  : 10px 0px;
    cursor  : pointer;
    position: relative;
}

.accordion.expanded .accordion_title:before {}

.accordion_arrow {
    width              : 22px;
    height             : 22px;
    background-image   : url(./assets/icons/arrow_down.png);
    background-repeat  : no-repeat;
    background-size    : 13px;
    background-position: center;
    display            : inline-block;
    position           : absolute;
    top                : 0px;
    right              : 0px;
    transition         : 0.2s ease transform;
}

.accordion.expanded .accordion_arrow {
    transform: rotate(180deg);
}

.ce-block__content {
    max-width: calc(100% - 70px);
}

.ce-toolbar__content {
    max-width: calc(100% - 70px);
}



.article_comment_container {
    display         : block;
    background-color: #F8FAFD;
    border-radius   : 10px 10px 0px 10px;
    margin-bottom   : 10px;
    padding         : 20px;
}

.article_comment_author {
    display      : block;
    text-align   : right;
    margin-top   : 5px;
    margin-bottom: 20px;
}

.article_comment_container.reply {
    margin-left: 20px;
}


.card {
    width           : 100%;
    height          : 100%;
    border-radius   : 10px;
    background-color: #F8FAFD;
    border          : 0px;
    padding-top     : 15px;
    padding-bottom  : 15px;
}

.mobile_header {
    display : none;
    height  : 40px;
    width   : 100%;
    padding : 20px;
    position: fixed;
    z-index : 99999999;
}

.burger_left {
    cursor  : pointer;
    display : none;
    position: relative;
    height  : 24px;
    width   : 30px;
    top     : 20px;
    left    : 20px;
    z-index : 999999999999;
}

.burger_left .burder_line {
    position  : absolute;
    top       : 0px;
    left      : 0px;
    transition: all 0.2s ease;
}

.burger_left .burder_line:nth-child(1) {
    top: 0px
}

.burger_left .burder_line:nth-child(2) {
    top: 9px
}

.burger_left .burder_line:nth-child(3) {
    top: 18px
}

.burger_right {
    background-image   : url('./assets/icons/arrow_down.png');
    background-repeat  : no-repeat;
    background-size    : 15px;
    background-position: center;
    transform          : rotate(90deg);
    width              : 30px;
    height             : 24px;
    display            : none;
    position           : fixed;
    top                : 20px;
    right              : 30px;
    cursor             : pointer;
    z-index            : 99999999;
    transition         : transform 0.2s ease;
}




.burger_left.open .burder_line:nth-child(1) {
    top      : 7px;
    transform: rotate(-45deg);
}

.burger_left.open .burder_line:nth-child(2) {
    display: none;
}

.burger_left.open .burder_line:nth-child(3) {
    top      : 7px;
    transform: rotate(45deg);
}

.burder_line {
    width           : 30px;
    border-radius   : 40px;
    height          : 4px;
    background-color: var(--green-color);
    display         : block;
    margin-bottom   : 4px;
}


.middle_container {
    width      : calc(100% - 280px);
    margin-left: 0px;
}

/* Statistics Graph */

.graph_container {
    background-color          : #F8FAFD;
    border-radius             : 20px;
    border-bottom-right-radius: 0px;
    padding                   : 30px !important;
}

.graph_container .tooltip {
    color: var(--green-color);
}

.graph_container * {
    font-family: 'Montserrat', sans-serif !important;
}

.graph_container strong {
    font-weight: 900 !important;
}

.graph_container #top-container {
    padding-bottom: 20px;
}

.graph_container #top-container #top-left {
    width: 0px !important;
}

div[role="tooltip"] {
    color: var(--green-color);
}


@media (max-width: 1300px) {

    .sidebar {
        width      : 310px;
        max-width  : 100vw;
        height     : 100vh;
        position   : fixed;
        margin-left: -310px;
        transition : margin 0.5s ease;
        z-index    : 999999999;
    }

    .sidebar.open {
        margin-left: 0px;
    }

    .sidebar_right {
        margin-right: -100%;
        width       : 100%;
    }

    .sidebar_right.open {
        margin-right: 0px;
    }

    .burger_right {
        display: block;
    }

    .burger_right.open {
        transform: rotate(-90deg);
    }

    .content_wrapper {
        width      : 100%;
        display    : inline-block;
        position   : relative;
        top        : 0px;
        left       : 0px;
        padding    : 0px;
        padding-top: 60px;
    }

    .burger_left {
        position: fixed;
        top     : 30px;
        left    : 30px;
        display : inline-block;
    }

    .row>div {
        margin-bottom: 30px;
    }

    .mobile_header {
        display: block;
    }

    .middle_container {
        width: 100%;
    }

    .go_back_button {
        top : 20px;
        left: 20px;
    }

}

.statusBadge {
    padding      : 7px 15px;
    border-radius: 20px;
    color        : white;
    display      : inline-block;
}

.statusBadge.valid {
    background-color: var(--green-color);
}

.statusBadge.invalid {
    background-color: var(--red-color)
}

.statusBadge.in_review {
    background-color: #F2E096;
}


.table_container {
    // border-radius: 20px;
    // box-shadow   : 0px 3px 20px rgba(0, 0, 0, 0.08);
}

.table_container thead tr th:first-child {
    // border-top-left-radius: 20px;
}

.table_container thead tr th:last-child {
    // border-top-right-radius: 20px;
}

.table_container tr:last-child td:first-child {
    // border-bottom-left-radius: 20px;
}

.table_container tr:last-child td:last-child {
    // border-bottom-right-radius: 20px;
}


.card {
    width           : 100%;
    background-color: white;
    border-radius   : 15px;
    box-shadow      : 0px 3px 20px rgba(0, 0, 0, 0.08);
    padding         : 20px;
    transition      : 0.2s ease all;
}

.card:hover {
    cursor    : pointer;
    box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.1);
}


.cardHeadline {
    font-size  : 50px;
    font-weight: 700;
}


.cardHeadline.green {
    color: #518E53
}

.cardHeadline.red {
    color: #EB4D4D
}

.cardHeadline.yellow {
    color: #EB9C4D
}


.footer {
    margin-top: 70px;
    text-align: center;
}

.footer a {
    margin: 20px 20px
}


.paginationContainer {
    width          : 100%;
    display        : flex;
    justify-content: flex-end;

    .paginationButton {
        margin         : 0px 5px;
        padding        : 5px 5px;
        display        : inline-flex;
        height         : 40px;
        width          : 40px;
        align-items    : center;
        justify-content: center;
        cursor         : pointer;
        border-radius  : 5px;
        transition     : 0.2s ease all;

        &:hover {
            background-color: white;
            box-shadow      : 0 3px 10px rgba(0, 0, 0, 0.08);
        }

        &.current {
            background-color: var(--green-color);
            box-shadow      : 0 3px 10px rgba(0, 0, 0, 0.08);
            color           : white;
        }
    }
}

.rdrDefinedRangesWrapper {
    display: none;
}