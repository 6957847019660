.container {
    padding      : 7px 15px;
    border-radius: 45px;
    display      : inline-block;
    margin-right : 5px;

    &.active, &.paid {
        background-color: var(--light-green-color);
    }

    &.paused, &.issued {
        background-color: var(--yellow-color);
    }

    &.canceled {
        background-color: var(--red-color);
    }
}